/*** Mobile Screens ***/

.sideBarContainer{
display: flex;
}
#sidebar{

  border-right: 1px solid #919191;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  float: right;
  position: relative;

  *{
    font-size: 1em;
  }

  .Level-1{
    display: none;
    ul{
      clear: both;
      float: left;
      width: 100%;
    }
  }

  button{
    position: absolute;
    top: 0px;
    border: none;
    outline: none !important;
    right: 0px;
    cursor: pointer;
    width: 30px;
    height: 28px;
    @include transition($t1);
    background-color: transparent;
    font-size: 1.3em;
    color: #fff;
    &:hover{
      color: #f9af00;
    }
  }
  .sidebar-heading{
    display: block;
    float: left;
    clear: both;
    width: 100%;

  font-size: 1.8em;
    a{
      padding: 5px 30px 5px 10px;
      display: block;
      text-decoration: none;
          color: #f9af00 !important;
    font-family: 'Cambo', serif;

    }
  }
  ul{
    ul{
      display: none;

      li{
        a{
          /*** Level Indentation ***/
        }
      }

      ul{
        li{
          a{
            /*** Level Indentation ***/
          }
        }
      }
    }
    li{
      clear: both;
      float: left;
      width: 100%;
      position: relative;
      a{
        display: block;
        color: #fff;
        padding: 5px 30px 5px 10px;
        text-decoration: none;
        @include transition($t1);
        background-color: rgba(0,0,0,0.0);

        span{
          color: #fff;
          @include transition($t1);
        }
        &:hover span{
          color: #f9af00 !important;
        }
      }
    }
  }
}

#sidebar-inner{
  overflow: hidden;
  float: left;
  clear: both;
  width: 100%;
}

/*** Desktop Screens ***/
@media all and (max-width: 1025px) {
  .flex{
    display: block !important;
  }
  .sideBarContainer{
    padding-right: 0px;
  }
  #sidebar{
  padding: 5px !important;
  }
  button.SidebarToggle.hideSideBarToggle {
    top: 15px !important;
}
.InnerPage .innerContent{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.innerPagecontent{
  padding-left: 20px !important;  
  padding-right: 20px !important;  
}

.tacMid{
  text-align: center;
}
  .clearMid{
    width: 100%;
    clear: both;
  }
}
@media all and (min-width: 1024px) {

  #sidebar{ 
    .Level-1{
      display: block !important;
      margin-top: 0% !important;
    }
    ul{
      ul{
        display: none;
      }
    }
    .mainToggle:first-of-type{
      display: none  !important;
    }
  }
  .hideSideBarToggle{
    display: none;
  }
}
