/*** Font Settings ***/
$font-stack: 'Lato', sans-serif;

/*** Color Settings ***/
$textLight:                 #eeeeee;
$textDark:                  #333333;
$contentColor:              #000000;
$HeadingColor:              #389FEC;
$LinkColor:                 #389FEC;
$themeColor:                #475f79;
$lightGrey:                 #c6c6c6;
$textGrey:                  #858584;
$mainColor:                 #c6c6c6;
$mainColor2:                #444444;

/*** Transitions Settings ***/
$t1:                      all 0.35s;
$t2:                      all  0.6s;

/*** Position Settings ***/
$center: translate(-50%,-50%);

/*** General Settings ***/
@mixin border-radius($radius) {
     -webkit-border-radius: $radius;
        -moz-border-radius: $radius;
         -ms-border-radius: $radius;
             border-radius: $radius;
}
@mixin box-shadow($input) {
         -webkit-box-shadow: $input;
            -moz-box-shadow: $input;
             -ms-box-shadow: $input;
              -o-box-shadow: $input;
                 box-shadow: $input;  
}
@mixin text-shadow($input) {
        -webkit-text-shadow: $input;
           -moz-text-shadow: $input;
            -ms-text-shadow: $input;
             -o-text-shadow: $input;
                text-shadow: $input;
}
@mixin transition($transition) {
    -webkit-transition: $transition;
       -moz-transition: $transition;
        -ms-transition: $transition;
            transition: $transition;
}
@mixin transform($transform) {
      -webkit-transform: $transform;
         -moz-transform: $transform;
          -ms-transform: $transform;
              transform: $transform;
}
@mixin box-sizing($box-sizing) {
    -webkit-box-sizing: $box-sizing;
       -moz-box-sizing: $box-sizing;
        -ms-box-sizing: $box-sizing;
            box-sizing: $box-sizing;
}
@mixin background-cover($image) {
        background-position: center;
            background-image:$image;
             background-size: cover;
}
@mixin stretchCover($background, $position, $index) {
       background-color: $background;
                 position: $position;
                     z-index: $index;
                        height: 100%;
                         width: 100%;
                           left: 0px;
                            top: 0px;
}
@mixin backface-visibility($input) {
  -webkit-backface-visibility: $input;
     -moz-backface-visibility: $input;
      -ms-backface-visibility: $input;
       -o-backface-visibility: $input;
          backface-visibility: $input; 
}
@mixin transform-origin($input){
     -webkit-transform-origin: $input;
        -moz-transform-origin: $input;
         -ms-transform-origin: $input;
          -o-transform-origin: $input;
             transform-origin: $input;
}
@mixin perspective($input){
          -webkit-perspective: $input;
             -moz-perspective: $input;
              -ms-perspective: $input; 
               -o-perspective: $input;
                  perspective: $input;
}
@mixin transform-style($input){ 
      -webkit-transform-style: $input;
         -moz-transform-style: $input;
          -ms-transform-style: $input;
           -o-transform-style: $input;
              transform-style: $input;
}
@mixin animation($input){
            -webkit-animation: $input;
               -moz-animation: $input;
                -ms-animation: $input;
                 -o-animation: $input;
                    animation: $input;
}
@mixin appearance($input){
             -webkit-appearance: none;
                -moz-appearance: none;
                 -ms-appearance: none;
                  -o-appearance: none;
                     appearance: none;
}

@mixin flex($input){
  display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: $input;
    -ms-flex-align: $input;
    align-items: $input;
    -webkit-justify-content: $input;
    -ms-flex-pack: $input;
    justify-content: $input;  
}

@mixin bezier-transform($input) {
  -webkit-transition-timing-function: $input;
  -moz-transition-timing-function: $input;
  -ms-transition-timing-function: $input;
  transition-timing-function: $input;
}
