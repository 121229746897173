/* -------------------- General Site layouts -------------------- **/
*{
	font-family: 'Muli', sans-serif;
	color: #929292;
	text-decoration: none !important;
	outline: none !important;
	-webkit-font-smoothing: antialiased;
}

button,
input[type=submit]{
	-webkit-appearance: none;
   -moz-appearance:    none;
   appearance:         none;
}

body{
	overflow-y: scroll;
	overflow-x: hidden;
    background-color: #465e79;
}

img{
	max-width: 100%;
}


.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */ 
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}

#allEvents ul li{
	margin-bottom: 10px;
	background-color: $themeColor;
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
}






.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: $themeColor;
    	@include transition($t1);
    	@include border-radius(3px);
    	/*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
	}
}


.eventTitle{
	color: #333 !important;
	padding: 10px;
	text-transform: uppercase;
}
.toggleBtn{
	cursor: pointer;
	sup{
	display: none !important;
	}
}

.calendar-widget-table tbody .calendar-header td{
	
	    background-color: $themeColor;
}


/*** Page ***/
.PageResults,
.InnerPage{
	.banner{
	height: 91vh;
	}
}

.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
	max-width: 500px;
	margin: 0px !important;
	padding: 0px !important;
}

#allEvents ul li{
	margin-bottom: 10px;
	background-color: $themeColor;
	float: left;
    width: 100%;
    clear: both;
    @include transition(background 0.35s);
    display: block;
}






.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #333 !important;
	}
	.url{
		padding: 10px;
    	color: #fff !important;
    	background-color: $themeColor;
    	@include transition($t1);
    	@include border-radius(3px);
    	/*&:hover{
    		background-color: #5a5962 !important;
    		color: #fff !important;
    	}*/
	}
}


.eventTitle{
	color: #333 !important;
	padding: 10px;
	text-transform: uppercase;
}
.toggleBtn{
	cursor: pointer;
	sup{
	display: none !important;
	}
}

.calendar-widget-table tbody .calendar-header td{
	
	    background-color: $themeColor;
}

.news-teaser{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;

	h3{
   		color: #f9af00 !important;
  		font-size: 1.5em;
  		/*font-family: 'Cambo', serif;*/
	}
	img{
		@include border-radius(50%);
	}
}

.bio h3{
	color: #f9af00 !important;
  		font-size: 1.5em;
  		text-transform: none !important;
  		/*font-family: 'Cambo', serif;*/
}


.noticesBG{
	position: fixed;
	display: none;
	top: 0px;
	left: 0px;
	z-index: 9999;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0, 0.75);
}
.noticesContainer{
	position: fixed;
	z-index: 9999;
	top: 50%;
	display: none;
	left: 50%;
	@include transform($center);
	padding: 30px;
	background-color: #dedede;
	max-width: 100%;
	width: 500px;
}
.NoticeTitle{
	text-transform: uppercase;
	font-weight: bold;
	padding: 0px !important;
    border-bottom: none !important;
    font-size: 1.3em;
}
.readMoreLinkNotices{
    font-size: 0.8em;
    border: 2px solid #333;
    padding: 3px 5px;
    margin-top: 10px;
    display: inline-block;
}
.fullWidth{
	position: relative;
	clear: both;
	width: 100%;
	float: left;
}
.closeNotices{
	position: absolute;
	top: 10px;
	right: 10px;
	padding-right: 20px;
	color: $themeColor;
	font-size: 0.8em;
	cursor: pointer;
	z-index: 9999; 

}
.closeNotices:after {
    content: "x";
    position: absolute;
    color: #d92d2a;
    display: block;
    right: 5px;
    top: 49%;
    text-transform: uppercase;
    @include transform(translateY(-50%));
}
.noticesInner{
	max-height: 90vh;
    overflow-y: auto;
    padding: 10px;
	*{
		color: #333;
	}
	.col-12{
		padding: 20px 0px;
		border-bottom: 2px solid #cccccc;
	}

}

.MainMenuClose{
	display: none !important;
}

.mainContent{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
	    max-width: 100%;
	 
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    max-width: 100%;
			background-color: #1b3080;
			&:hover{
		    	background-color: #5a5962 !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
.feed a,
.addToCalendar,
.attachment,
.readMoreLink,
.button{
		padding: 10px 20px !important;
	    display: inline-block;
	    text-align: center;
	    color: #fff;
	    font-size: 0.7em !important;
	    cursor: pointer;
	    *{
	    	font-size: 1em;
	    	color: #fff;

	    @include transition($t1);
	    }
	    border: none !important;
	    color: #fff !important;
	    margin-top: 10px;
	    @include transition($t1);
	    @include border-radius(3px);
	   	background-color: #f9af01;
		&:hover{
	    	background-color: #4b4b4b !important;
	    }
}

/** Inputs  **/
#content{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    background-color: #f9af01;
			max-width: none !important;
		    width: auto !important;
		    display: inline-block;

			&:hover{
	    	    background-color: #4d4d4d !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
/** Inputs  **/



.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}



.heading-2{
	color: $themeColor !important;font-weight: lighter;
	*{color: $themeColor !important;font-size: 1em;font-weight: lighter;}
}
.archives{
	position: relative;
	display: block;
	float: left;
	width: 100%;
	clear: both;
	padding: 0px !important;
	margin: 0px 0px 30px 0px !important;

	.archive{
		position: relative;
		display: block;
		float: left;
		clear: both;
		color: #fff;
		width: 100%;
		margin: 5px 0px !important;
		cursor: pointer;
		@include transition($t1);
		img{
			position: absolute;
		    right: 10px;
		    top: 50%;
		    max-width: 17px;
			@include transform(translateY(-50%));
		}
		a{
			display: block;
			width: 100%;
			clear: both;
			float: left;
			padding: 8px 30px  8px 15px;
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 1;
			height: 100%;
		}
		*{
		@include transition($t1);
			color: #fff !important;
		}
		
		@include border-radius(3px);

	    background-color: #f9af01 !important;

		 &:hover{
	    	    background-color: #4d4d4d !important;
	    		*{
	    			color: #fff !important;
	    		}
		    }
	}
}

.SubDate{
	margin-top: 20px;
    font-size: 1.4em;
    margin-bottom: 10px;
}


.archive{
	*{
		color: #fff !important;
	}
}
/*
|===============================================================
|	Uploads
|===============================================================
*/

.upload{
	position: relative;
	display: block;
	width: 100%;
	max-width: 500px;
	clear: both;
	float: left;
	padding: 10px 45px 12px 30px;
	border: 1px solid $themeColor;
	margin: 5px 0px;
	background-color: transparent;
	@include transition($t1);
	.uploadTitle{
		text-transform: uppercase;
		font-size: 1em;
		font-weight: normal !important;
		color: $themeColor !important;
	    display: inline-block;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.fileIcon{


		position: absolute;
		right: 10px;
		top: 50%;
		@include transform(translateY(-50%));



	    background-color: $themeColor;
	    display: inline-block;
	    float: right;
	    width: 25px;
	    height: 30px;
	    @include transition($t1);
	    span{
	    	@include transition($t1);
	    	color: #fff !important;
		    background-color: #000;
		    height: 13px;
		    width: 100%;
		    padding-top: 2px;
		    font-size: 7px;
		    text-transform: uppercase;
		    display: block;
		    text-align: center;
		    margin-top: 8px;
	    }
	}

	&:hover{
		background-color: #e2e0df !important;
		color: #1b3080 !important;
		.uploadTitle{
			/*color: #000 !important;*/
		}
		/*.fileIcon{
	    	background-color: #000 !important;
			span{
			    color: #000 !important;
				background-color: #009e7f;
		   }
		}*/
	}
}


.upload{
	/*padding: 10px;
	border-bottom: 2px solid #eee;*/
	*{
		vertical-align: middle;
		font-weight: lighter !important;
	}
}





.galleryImage{
	display: block;
	width: 33.33%;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
	height: 500px;
	li {
		height: 100%;
		text-align: center;
		img {
			display:inline-block;
			height: 100%;
		}
	}
}
.innerSlideGal{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.GalNext,
.GalPrev{
	cursor: pointer;
	position: absolute;
	z-index: 200;
	top: 50%;
	margin-top: 8px;
	background-size: 80%;
	background-position: center;
	background-repeat: no-repeat;
	@include transform(translateY(-50%));
	
}

.GalNext{
	left: 0px;
	padding: 20px;
	background-image: url(../images/left.png);
	background-color: rgba(0,0,0,0.4);
}
.GalPrev{
	right: 0px;
	background-image: url(../images/right.png);
	padding: 20px;
	background-color: rgba(0,0,0,0.4);
}

.bx_pages_frame {
	position: relative;
	#bx_pages {
		margin-top: 20px;
		li {
			background-position: center center;
			cursor: pointer;
			height: 200px;
			    opacity: 0.5;
			    @include transition(opacity 0.35s);
			    &:hover{
			   	 opacity: 1 !important;
			    }
		}
	}
	.controls {
		i {
			position: absolute;
			top: 50%;
			color: $mainColor2;
			font-size: 24px;
			margin-top: -12px;
			cursor: pointer;
			@include transition(all 0.5s);
			&:hover {
				opacity: 0.5;
			}
		}
		i.prev {
			left: 0;
		}
		i.next {
			right: 0;
		}
	}
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }


.galleryImage{
	width: 33.33%;
    float: left;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}
#CustomLoginForm_LoginForm{
	max-width: 400px;
	width: 100%;
}

.staffProfile {
	.staffProfilePic {
		width:200px;
		height:200px;
		background-size: cover;
		background-position:center center;
		border-radius:20px;
	}
}
.HorizontalStack {
	.staffProfile {
		width:auto;
		margin:0 30px 30px 0;
		.profilePic {
			margin:0 !important;
		}
	}
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.bio{
	h4{
		font-weight: normal;
    	font-size: 1.2em;
	}

	h3{
		text-transform: uppercase;
		font-weight: lighter;
	}
}

 .HorizontalStack .staffProfile {
    width: auto;
    margin: 0 30px 30px 0;
}

.VerticalStack{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.NameDepOnly{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.HorizontalStack{
	.profilePic{
		padding-bottom: 20px;
	}
}

.profilePic img{
	display: inline-block;
	padding-bottom: 20px;
}

.VerticalStack{
	.profilePic img{
		display: inline-block;
		padding-bottom: 0px;
	}
}


#SearchResults{ 
	padding: 0px;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
	font-size: 0.8em;
	li{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
		list-style: none !important;
	}
	.readMoreLink{
		font-size: 1em !important;
	}
}


.NewsletterItem{
	display: block;
	width: 25%;
	float: left;
	padding: 10px;
	.NewsletterInner{
		display: block;
		position: relative;
		width: 100%;
		@include border-radius(3px);
		@include transition($t1);
		border: 5px solid #fff;
		@include box-shadow(0px 0px 10px 0px #9c9c9c);
		overflow: hidden;
		.newsletterThumb{
			float: left;
			clear: both;
			background-color: #eee;
			width: 100%;
			background-image: url(../images/newsletter.png);
			background-size: 60%;
    		background-repeat: no-repeat;
			background-position: center;
			padding-bottom: 110%;
		}
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
	}
	&:hover{
		.NewsletterInner{
			border: 5px solid #f9af00 !important;
		}
	}
	.newsletterTitle{
		padding: 10px;
		font-size: 0.8em;
		float: left;
	    width: 100%;
	    text-align: center;
	    clear: both;
	}
}

.Newsletter{
	width: 20%;
    padding-bottom:20%;
    position: relative;
	min-width: 200px;
    min-height: 200px;
    display: inline-block;
    float: none;
}
.NewsLetterInner{
	top: 50%;
	left: 50%;
	width: 90%;
	height: 90%;
	border-radius: 3px;
	overflow: hidden;
	position: absolute; 
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
}



.NewsLetterInner .imgContainer{
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.Newsletter a{
	position: absolute; 
	width: 100%;
	height: 100%;
	z-index: 200;
	left: 0px;
    top: 0px;
}
.Newsletter:hover .NewsLetterInner{
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-ms-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
}

.Newsletter .downloadInfo{
	position: absolute;
	transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	left: 50%;
	color: #777;
	width: 98%;
	text-align: center;
	font-size: 1em;
	bottom: 10%;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
}

.Newsletter:hover .downloadInfo{
	
    color: #e42c25 !important;
}

.NewsletterTitle{
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 30px;
	margin-top: 50px;
	text-align: center;
	font-size: 1.5em;
	color: #303c68 !important;
	*{
		font-weight: lighter;
		color: #303c68 !important;
	}
}

.InnerPage{
    .calPrev,
    .calNext {
        top: 37px;
    }
    .calendar-widget-table,
    .outerCalendarContainer{
        width: 100%;
    }
    .eventContainer{
        display: none !important;
    }
    .calHeader{
        .col-7,
        .col-5{
            width: 100%;
            text-align: center;
        }
        .col-5{
            display: none !important;
        }
    }
}
.group1{
	@include transition($t1);
	opacity: 0;
}
.PageLoaded{
	.group1{
		opacity: 1 !important;
	}
}
#gmap_canvas{
	min-height: 400px;
}




.searchToggleButton{
	position: absolute;
	left: 50%;
	top: 7px;
	@include transform(translateX(-50%));
}



/** General Site layouts **/
















.CenterItems{
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex{
	display: flex;
}

.slink{
	cursor: pointer;
}


.translate{
	cursor: pointer;
}
.TranslateBG{
	display: none;
	position: fixed;
	top: 0px;
	z-index: 9999;
	left: 0px;
	width: 100%;
	height: 100%;   
	 background-color: rgba(0, 0, 0, 0.76);
}
#google_translate_element{
	display: none;
	position: fixed; 
	z-index: 9999;
	top: 50%;
	left: 50%;
	@include transform($center);
}

#gmap_canvas{
	min-height: 500px;
}

/*** Scroll Bar ***/
::-webkit-scrollbar{
	width:8px;
	height:7px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0; 
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color: #2b2b2b;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: #768daf;
	@include border-radius(0px); 
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: #768daf;
}



.db{
	display: block;
}

fieldset{
	border: none;
}

/*** Search Form ***/


/*** HomePage ***/
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	height: 100vh;
	@include background-cover(url("../images/bg.jpg"));

	.darken{
		@include stretchCover(rgba(43,43,43, 0.2), absolute, 1)
	}

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		@include background-cover(url("../images/bg.jpg"));
	}
}

.bannerBg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	@include background-cover(url("../images/bg.jpg"));
}


#google_translate_element{
    border: 30px solid #f9af00;
}


/*** Page ***/
.Page{
	.banner{
		height: 91vh;
	}
}
/*** Footer ***/
footer{
	padding: 30px 0px 0px 0px;
}


/*** Menu ***/
#Menu{
	float: left;
    z-index: 9999;
    width: 100%;
    text-align: center;
	display: inline-block;
	background-color: #f9af00;
	*{
		color: #000;
	}
	.leftSide{
		padding-right: 60px;
	}
	.rightSide{
		padding-left: 60px;
	}
	/*
	.MenuButton{
		display: inline-block;
		position: absolute;
		z-index: 2;
		cursor: pointer;
		background-color: #73a8d9;
	    right: 0px;
	    padding: 8px 12px 7px 12px;
	    cursor: pointer;
	    opacity: 1;
	    top: 0px;
	    text-transform: uppercase;
	}
	*/
	.MenuContainer{
		display: inline-block;  
    	text-align: center;/*
		position: relative;*/
		.LevelOne{
			display: inline-block; 
			text-align: center;
			/*position: relative;*/
			&:before{
				opacity: 0;
				content:'';
				position: absolute;
				display: block;
				height: 5px;
				width: 40px;
				left: 50%;
				bottom: 0px;
				@include transform(translateX(-50%));
				background-color: #faba38;
				@include transition($t1);
			}
			&:hover:before{
				opacity: 1 !important;
			}
			a{    
				color: #626261;
				font-size: 1em;
				@include transition($t1);    
				padding: 14px 20px 14px 20px;
			    font-size: 1em;
			    line-height: 3em;
			    display: inline-block; 
	   	 		line-height: 1em;
    			font-weight: 800; 
			}
		}
	}


	.SubMenu{
		@include box-shadow(-2px 13px 40px 0px rgba(47, 47, 47, 0.33));
		width: 100%;
		height: 0px;
		font-size: 0.7em;
		overflow: hidden;
		display: block;
		z-index: -9999;
    	left: 50%;
    	text-align: left;
		position: absolute;
	    background-color: #626261;
	    border-radius: 0px;
	    margin-top: 0px;
	    padding: 10px;
	   /* padding: 3px 10px 6px 10px;*/
	    opacity: 0;
	    display: none;
	    /*transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -o-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -ms-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -moz-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -webkit-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;*/
	    /*@include transform(translateY(20px) translateX(-50%) scale(0.98));*/

	    @include transform(translateY(0px) translateX(-50%) scale(1) !important)
	    li{
	    	display: block;
		    width: 100%;
		    float: left;
	    }
	    a{
		   display: block;
		    padding: 10px 20px !important;
		    /*font-size: 0.9em;*/
		    line-height: 1.2em !important;
		    width: 100%;
		    color: #fff !important;
		    font-weight: bold;
			background-color: transparent;
	    	@include transition($t1);
			&:hover{
    			color: #f9af00 !important;
			}
		}
	}

	.active{
		.topMenuLink{
	    	background-color: #626261;
	    	color: #f9af00 !important;
		}
	}
	.LevelOne{
		.SubMenu{
			left: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
		}
	}
	.menuItem-Last{
		.SubMenu{
			left: auto;
			right: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
		}
	}
	.active .SubMenu{
		display: block !important;
		/*opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(-50%) scale(1) !important);*/
	}
/*	.menuItem-1.active .SubMenu,*/
	.LevelOne .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(0%) scale(1) !important);
	}
	/*.menuItem-Last.active .SubMenu{
			opacity: 1 !important;
			left: auto;
			right: 0%;
			@include transform(translateY(0px) translateX(0%) scale(1) !important);	
	}
*/
	/*.SubMenu:before {
	    content: '';
	    display: block;
	    position: absolute;
	    width: 100%;
	    height: 17px;
	    left: 0px;
	    top: -14px;
	    z-index: 2;
	}
	.SubMenu:after {
	    content: '';
	    display: block;
	    position: absolute;
	    border: 10px solid transparent;
	    top: -20px;
	    left: 50%;
	    margin-left: -10px;
	    border-bottom: 10px solid #2f3b67;
	}*/
}

.LevelOne a.topMenuLink{
	position: relative;
	&:after{
		content:'';
		height: 50%;
		width: 2px;
		position: absolute;
		display: block;
		background-color: #fff;
		right: 0px;
		top: 50%;
		@include transform(translateY(-50%));
	}
}
.LevelOne:last-of-type a.topMenuLink {
	&:after{
		display: none;
	}
}

.flexslider .flex-direction-nav {
    position: absolute;
    bottom: 16%;
    left: 50%;
    height: 30px;  
    z-index: 800;
    width: calc(100% - 80px);
    @include transform($center);
}
.flexslider .slides li {
    position: relative;
    height: 100vh;
}
.quoteBox {
    position: absolute;
    top: 50%;
    left: 150px;
    @include transform(translate(0%, -50%));
    z-index: 2;
    color: #fff;
    font-size: 2em;
    display: block;
    opacity: 1;
    line-height: 1.2em;
    text-transform: uppercase;
    *{
    	color: #fff !important;
    }
}

.slideBG{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-image: url(../images/bg.jpg);
}
/* -------------------- General Site layouts -------------------- **/




/* -------------------- Site Build -------------------- **/
#MenuIcon{
	display: none;
}

#header{
	width: 100%;
	position: fixed; 
	z-index: 9990;
	background-color: rgba(255,255,255,0.9);
	text-align: center;
	@include box-shadow(0px 0px 40px 0px rgba(0,0,0,0.4));
}
.homebtn{
	display: inline-block;
	position: relative;
	margin-bottom: 30px;
	margin-top: 20px;
	@include transition($t1);
	img{
		width: 150px;
		@include transition($t1);
	}
	span{
		@include transition($t1);
		font-size: 2.5em;
		display: block; 
		font-weight: bold;
		color: #626261;
	}
}

.ScrollNav{
	.homebtn{
		margin-bottom: 10px;
		margin-top: 10px;
		img{
			width: 50px;
		}
		span{
			font-size: 1em;
		}
	}
}


.bannerText{
	position: absolute;
	z-index: 90;
	text-align: center;
	left: 50%;
	width: 80%;
	bottom: 200px;
	font-size: 1.3em;
	@include transform(translateX(-50%));
	*{
		color: #fff;	
	}
	.part-1{
		padding-bottom: 30px;
		font-size: 3em;
		/*font-family: 'Cambo', serif !important;*/
		
/* 
		&:before{
			content: '';
			position: absolute;
			display: block;
			height: 5px;
			width: 70px;
			background-color: #f9af00;
			left: 50%;
			bottom: 7px;
			@include transform(translateX(-50%));
		}
		*/
	}
	.part-2{
		font-size: 2em;
	}
	.down{
		margin-top: 20px;
		width: 50px;
		cursor: pointer;
	}
}
#Home1{
	overflow: hidden;
	padding: 100px 20px;
	background-color: #626261;
	*{
		color: #fff;
	}
	.inner{
		width: 1000px;
		z-index: 90; 
	}

	.overlay1{
		position: absolute;
		top: 50%;
		height: 85%;
		@include transform(translateY(-50%));
		left: -7%;
		opacity: 0.2;
		z-index: 1; 
	}
	.overlay2{
		position: absolute;
		top: 50%;
		height: 85%;
		@include transform(translateY(-50%) scaleX(-1));
		right: -7%;
		opacity: 0.2;		
		z-index: 1; 
		filter: FlipH;
        -ms-filter: "FlipH";
	}
}

.readMore{
	margin-top: 20px;
	display: inline-block;
	text-align: center;
	img{
		display: inline-block;	
		width: 50px;
	}
	span{
		display: block;
		margin-top: 10px;
		color: #f9af00 !important;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 0.8em;
	}
}

.pageTitle{
	font-size: 2.5em;
	/*font-family: 'Cambo', serif;*/
}

#Home2{
	padding: 100px 20px;
	background-color: #fff;
}
.LinkBox{
	
	.linkBoxTitle{
		text-align: center;
		color: #575756;
		/*font-family: 'Cambo', serif;*/
		font-size: 2.5em;
		padding-bottom: 20px;
		&:after{
			content: '';
			position: absolute;
			height: 5px;
			bottom: 10px;
			left: 50%;
			@include transform(translateX(-50%));
			background-color: #f9af00;
			width: 70px;
		}
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.LinkboxPdding{
		padding: 20px 18% 0px 18%;
}


.linkBoxTop{
		padding-bottom: 100%;
	}
	.linkBoxCircle{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 74%;
		height: 74%;
		background-color: #fde8bb;
		@include transform($center scale(1));
		@include transition($t1);
		@include border-radius(50%);
	}
.linkBoxTop img{
	position: absolute;
	top: 50%;
	left: 50%;
		@include transition($t1);
	height: 69%;
	@include transform($center  scale(1));
}
.LinkBox:hover{
	.linkBoxCircle{
		@include transform($center scale(1.1));
	}

	.linkBoxTop img{
		@include transform($center  scale(0.9));
	}
}
#Home4{
	z-index: 5;
	background-color: #f9af00;

	.leftImage{
		background-position: center;
		position: absolute;
		height: calc(100% + 60px);
		margin-top: -30px;
		width: 48%;
		background-size: cover;
		background-color: #000;
		.watchFull{
			position: absolute;
			bottom: 30px;
			left: 50%;
			cursor: pointer;
			width: 200px;
			display: inline-block;
			text-align: center;
			color: #fff;
			@include transform(translateX(-50%));
			padding: 20px;
			text-transform: uppercase;
			&:before{
				content: '';
				display: block;
				width: 70px;
				left: 50%;
				top: 0px;
				height: 5px;
				@include transform(translateX(-50%));
				position: absolute;
				background-color: #f9af00;
			}
		}
	}
	.rightContent{
		float: right;
	}
	.prev,
	.next{
		position: absolute;
		top: 50%;
			@include transition(all 150ms);
		@include transform(translateY(-50%) scale(1));
		*{
			color: #fff;
			font-size: 3em;
			@include transition($t1);
		}
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
		&:hover{
			@include transform(translateY(-50%) scale(1.1));
		}
	}
	.prev{
		left: -10px;
	}
	.next{
		right: -10px;
	}
	.slideInner{
		background-color: #fff;
		padding: 20px;
	}
	.pageTitle{
		color: #fff !important;
	}
}

.NewsSlideTitle{
	padding-bottom: 70px;
	color: #626261;
	font-size: 2.1em;
}
.newsReadMore{
	img{
		display: inline-block;
		vertical-align: middle;
		width: 40px;
		@include transform(rotate(90deg));
	}
	span{
		display: inline-block;
		vertical-align: middle;
		color: #626261;
		text-transform: uppercase;
		font-size: 0.8em;
		padding-right: 15px;
	}
}

.NewsFull{
	margin-top: 40px;
	display: inline-block;
	color: #fff;
	padding: 20px;
	position: relative;
	text-transform: uppercase;
	&:before{
		content: '';
		display: block;
		width: 70px;
		left: 50%;
		top: 0px;
		height: 5px;
		@include transform(translateX(-50%));
		position: absolute;
		background-color: #fff;
	}
}

.EventsFull{
	margin-top: 40px;
	display: inline-block;
	color: #626261;
	padding: 20px;
	position: relative;
	text-transform: uppercase;
	&:before{
		content: '';
		display: block;
		width: 70px;
		left: 50%;
		top: 0px;
		height: 5px;
		@include transform(translateX(-50%));
		position: absolute;
		background-color: #f9af01;
	}
}

#Home5{
	background-color: #fff;
	padding: 100px 20px;
	z-index: 2;
	min-height: 50vh;

	.inner{
		z-index: 80;
	}

	.bird3{
		width: 100px;
	}

	.overlay1{
		position: absolute;
		top: 50%;
		height: 85%;
		@include transform(translateY(-50%));
		left: -7%;
		opacity: 0.2;
		z-index: 1; 
	}
	.overlay2{
		position: absolute;
		top: 50%;
		height: 85%;
		@include transform(translateY(-50%) scaleX(-1));
		right: -7%;
		opacity: 0.2;		
		z-index: 1; 
		filter: FlipH;
        -ms-filter: "FlipH";
	}
}

#Home6{
	height: 60vh;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	-webkit-background-attachment: fixed;
	background-image: url(../images/bg.jpg);
}

.EventInner{
	background-color: #626261;
	*{
		color: #fff;
	}
	.EventDate{
		background-color: #fff;
		padding-bottom: 100%;
		span{
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform($center);
			width: 80%;
			text-align: center;
			color: #f9af01;
			font-size: 3em;
		}
	}
	.eventTitleSlide{
		/*font-family: 'Cambo', serif;*/
		color: #fff !important;
		padding: 0px 20px;
		*{
			color: #fff !important;
		}
		font-size: 1.7em;
		padding-top: 0px !important;
	}

}
.EventBottom{
	position: absolute;
	bottom: 0px;
	width: calc(100% - 20px);
	left: 20px;
    padding: 5px;
	border-top: 1px solid #fff;
	font-size: 0.8em;
	img{
		display: inline-block;
		vertical-align: middle;
		color: #fff;
		height: 15px;
		margin-right: 10px;
	}	
	span{
		display: inline-block;
		vertical-align: middle;
		color: #fff;
	}
}

.CalEventSlide{
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	i{
		color: #f9af02;
		display: inline-block;
		vertical-align: middle;
	}
	*{
		@include transition($t1);
	}
	&:hover{
		.EventInner{
			background-color: #f9af02 !important;
		}
		.col-9{
			*{
				color: #475f79 !important;
			}
		}
	}
}
/* -------------------- Site Build -------------------- **/
/* -------------------- Ticker -------------------- **/
a.important{
	color: #c00;
	font-weight: bold;
}

#tickerContainer{
	clear: both;
	position: relative;
	bottom: 0;
	padding: 0px;
	width: 100%;
	overflow: hidden;
	max-height: 49px;
}



.tickertape{
	float: left;
	position: relative;
	overflow: hidden;
}
 
.tickertape section,
.tickertape span{
    font-size: 1em;
	color: #fff;
}

.tickertape a{
	color: #fff;
	font-size: 1em;
}

.tickertape section{
	white-space: nowrap;
	display:inline-block;
	float: left;
	padding: 13px 0 15px 0;
	position: relative;
	text-align: center;
	color: #fff;
}

.ticker{
	z-index: 200;
	position: absolute;
	bottom: 50px;
	top: auto;
	max-height: 49px;
	width: 100%;
	background-color: #f9af01;
}

#LatestNews{
	position: absolute;
    left: 0px;
    font-size: 1em;
    padding-left: 10px;
    background: #f9af01;
    height: 47px;
    color: #fff;
    z-index: 2;
    line-height: 45px;
    text-transform: uppercase;
    padding-right: 20px;
}

#footer{
	background-color: #fff;
	padding: 20px;
}
img{
		max-width: 100%;
	}
.footerLogo{
	width: 150px;
	display: inline-block;
}

.tYellow{
	color: #f9af00;
}
.logos{
	padding: 30px 0px;
	text-align: right;
	img{
		height: 70px;
		margin: 4px;
		display: inline-block;
		vertical-align: middle;
	}
}

.fse{
	display: inline-block;
	vertical-align: bottom;
	font-size: 0.7em;
	*{
		font-size: 1em;
	}
}
#up{
	margin-left: 30px;
	display: inline-block;
	vertical-align: bottom;
	width: 50px;
	cursor: pointer;
}
.addressSection{
	font-weight: bolder;
}

/*
|===============================================================
|	Video Page
|===============================================================
*/
/*** Video Page ***/
.videoHeader{
	height: 126px;
	background-color: #000;
}
.fullScreenBlackout{
	display: none;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	background-color: #000;
	height: 100%;
	z-index: 9998;
}
.videoContainer{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
	overflow: hidden;
}
.video_background{
/*
	width: 125% !important;
	height: 114% !important;
*/
    width: 151% !important;
    height: 152% !important;
	position: absolute !important;
	top: 50%;
	left: 50%;
	@include transform($center);
}

.active.video_background{
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 9999;
}
.active{
	.closeVideo{
		display: block !important;
	}
}


.videoBlackout{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	@include transition($t1);
	height: 100%;
	background-color: rgba(0,0,0, 0.6);
}

.videoContainer:hover{
	.videoBlackout{
		background-color: rgba(0,0,0, 0.4) !important;
	}
}

.videoTitle{
	 font-family: geomanistblack;
	 font-size: 2.8em;
	 color: #fff !important;
	 margin-bottom: 20px;
	 text-align: center;
}
.mejs__container * {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.closeVideo{
	display: none;
	position: fixed;
	top: 10px;
	right: 10px;
	z-index: 9999;
	cursor: pointer;
	color: #fff;
	font-size: 1.8em;
	i{color: #fff;}
}

.videoCenter{
	display: inline-block;
	position: absolute;
	left: 0%;
	width: 100%;
    bottom: 0px;
    img{
    	max-width: 110px;
	    display: block;
	    margin: 0 auto;
    	margin-bottom: 30px;
    }
}


.video_background.active{
	background-color: #000 !important;
}

.VideoCenter{
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 5;
	@include transform($center);
}

.videoWrapper .videoContainer:nth-child(odd):last-of-type{
	padding-bottom: 50% !important;
	width: 100% !important;
}


body.oh{
	overflow: hidden !important;
	overflow-x: hidden !important;
	overflow-y: hidden !important;
	max-height: 100vh;
}
.oh{
	overflow: hidden !important;
}
.activeVideo{
	overflow: hidden !important;
	.rightContent,
	header{
		display: none;
	}
	*{
		z-index: 1;
	}
	.closeVideo,
	#Home4{
		z-index: 9999;
	}
}
/*** Video Page ***/

.emailFooter{
    word-break: break-all;
}

.PageContainer{
	background-color: #fff;  
	z-index: 90;
}
.sideBarContainer{
/*	padding-right: 30px; */
	#sidebar{
		padding: 20px;
		margin-top: 0px;
		margin-bottom: 0px;
		background-color: #929292;
	}
}

.mainContent{
	background-color: #fff;
	.pageTitle{
		color: #f9af00 !important;
	}
	p{
		color: #929292;
	}
}

.innerContent{
	width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    margin-top: -200px;
}
.innerPagecontent{
	padding-left: 10%;
	padding-right: 10%;
}

#breadcrumbs{
	text-align: center;
    background-color: #f9af01;
    padding: 10px 20px;
    color: #fff;
    *{color: #fff;}
}
.Rightimg{
	margin-bottom: 20px;
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
}

.ImagePadding{
	padding-right: 0px !important;
}


.FileUpload{
	overflow: hidden;
	cursor: pointer;
	@include transition($t1);
	display: inline-block;
	width: 100%;
	max-width: 500px;
	padding: 10px 20px;
	text-transform: uppercase;
	border: 2px solid #ededed;
	color: #2b2b2b;
	color: $themeColor !important;
	padding-left: 50px;
	position: relative;
	*{
		color: $themeColor;
	}
	&:before{
		@include transition($t1);
		content: '\f019';
		display: block;
		position: absolute;
		font-family: FontAwesome;
		top: 50%;
		opacity: 0;
		left: 10px;
			color: $themeColor;
		*{
			color: $themeColor;
		}
		@include transform(translateY(-100%));
	}
	&:after{
		@include transition($t1);
		display: block;
		content: '\f0f6';
		position: absolute;
		font-family: FontAwesome;
		top: 50%;
		left: 10px;
		opacity: 1;
			color: $themeColor;
		*{
			color: $themeColor;
		}
		@include transform(translateY(-50%));
	}
	&:hover{

			color: $themeColor;
		border: 2px solid $themeColor;
		&:before{
			color: $themeColor;
			opacity: 1;
			@include transform(translateY(-50%));
		}
		&:after{
			color: $themeColor;
			opacity: 0;
			@include transform(translateY(50%));
		}
	}

	.fileIcon{
		display: none;
	}
}
.heading-2 {
 color: #f9af00 !important;
  		font-size: 1.5em;
  		font-weight: normal;
  		/*font-family: 'Cambo', serif;*/
* {
   		color: #f9af00 !important;
  		font-size: 1em;
  		font-weight: normal;
  		/*font-family: 'Cambo', serif;*/
	}
	}



/*
|===============================================================
|	Calendar Page Template Widget
|===============================================================
*/
.calendar-widget-table {
	width: 100%;
	background-color: #fff;
	margin:0 0 2em;
	*{
		text-align: center;
	}
	thead {
		tr {
			th {
				border: none;
    			padding: 15px 20px;
				background-color: #475f79;
				    font-size: 2.8em;
				.show-month, .next, .prev {
					display:inline-block;
					color: #FFFFFF;
					font-weight: bold;
					font-weight: lighter;
    				font-size: 0.7em;
					i {
						color: #fff;
					}
					.inner {
						color: #FFFFFF;
						width: auto;
					}
				}
				.show-month {
					padding: 0 20px;
				}
			}
		}
	}
	.calendar-header {
		td.calendar-header-day, td {
			background: transparent !important;
			color: #475f79;
			border:none !important;
		}
	}
	tbody{
		.calendar-day {
			border: 1px solid #ececec;
			color: #a9afb9;
			@include transition($t1);
		}
		tr {
			&:nth-child(even){ 
				background-color:transparent; 
			}
			td {
				padding: 10px 0px !important;
				z-index: 1;
				text-align: center !important;
				.calEventPopup {
					position: absolute;
					z-index: 80 !important;
					padding:20px;
					border:2px solid $LinkColor;
					background-color: #fff;
					    width: 180px;
					    text-align: center;
					    display:none;
					.dayTitle {
						color:$LinkColor;
						margin-bottom:20px;
						font-weight:500;
					}
					.eventLine {
						color:$LinkColor;
					}

				}
				&.hasEvent:hover {
					.calEventPopup {
						display: block;
						z-index: 90 !important;
					}
				}
				&:nth-child(1) .calEventPopup { margin-left:0; }
				&:nth-child(2) .calEventPopup { margin-left:-20px; }
				&:nth-child(3) .calEventPopup { margin-left:-40px; }
				&:nth-child(4) .calEventPopup { margin-left:50%; @include transform(translateX(-50%)); }
				&:nth-child(5) .calEventPopup { margin-right:-40px; }
				&:nth-child(6) .calEventPopup { margin-right:-20px; }
				&:nth-child(7) .calEventPopup { margin-right:0px; }
			}
		}
		
		.today {
			color: #676b71;
		}
		.hasEvent {
			background-repeat:no-repeat;
			background-position:10px 10px;
			background-size: 4px 4px;
		}
		.selected{color: #fff !important;}
		.out-of-month {
			background-color:transparent !important;
			color: #efefef;
		}
	} 
	.calendar-day:hover, .selected {
		background-color: rgba(71, 95, 121, 0.05) !important;
		color: #FFFFFF;
		font-weight: 500;
	}
}

.calendarWidget{
    padding: 10px;
}

/*
|===============================================================
|	Calendar Filter
|===============================================================
*/

.CalendarFilter{
    padding: 10px; 
}

/* .CalendarFilter{
    span{
        display: inline-block;
        padding: 5px 0px;
        margin: 0px 5px;
        cursor: pointer;
    }
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/*     display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
    display: block;
} */
.CalendarFilter span{
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    color: #333 !important;
    border-bottom: 2px solid transparent;
    margin-bottom: 10px;
}
.CalendarFilter span.active{
    border-bottom: 2px solid $themeColor !important;
    color: $themeColor !important;
}
.noEvents{
    max-width: 500px;
    display: none;
    padding: 20px 10px;
    color: #666 !important;
    @include border-radius(3px);
    background-color: #eee;
}

#allEvents ul{
    max-width: 500px;
    margin: 0px !important;
    padding: 0px !important;
}
#allEvents ul li,
#HomepageEvents ul li{
    @include border-radius(5px);
    margin-bottom: 10px;
    background-color: #475f79 ;
    float: left;
    width: 100%;
    clear: both;
    margin-left: 0;
	span.suffix {
		font-size: 12px;
		vertical-align: top;
		margin-left: 2px;
		line-height: 6px;
	}
    @include transition(background 0.35s);
    display: block;
    
    .toggleBtn{
        cursor: pointer;
        padding: 10px;
        padding-left: 20px;
        color: #fff;
        *{
			color: #fff;
        }
		.arrow {
			position: absolute;
			top: 15px;
			right: 15px;
			color: #fff;

		    -webkit-transition-duration: 0.8s;
			-moz-transition-duration: 0.8s;
			-o-transition-duration: 0.8s;
			transition-duration: 0.8s;

			-webkit-transition-property: -webkit-transform;
			-moz-transition-property: -moz-transform;
			-o-transition-property: -o-transform;
			 transition-property: transform;
		}
		&.active {
			.arrow {
				@include transform(rotate(180deg));
			}
		}
    }
}

.toggleContent{
	display: none;
	font-size: 0.8em;
	*{
		font-size: 1em;
		color: #fff !important;
	}
	.url{
		padding: 10px;
    	color: #333 !important;
    	background-color: #f7c832;
    	@include transition($t1);
    	&:hover{
    		background-color: #4d4d4d !important;
    		color: #fff !important;
    	}
	}
}
.toggleBtn{
	cursor: pointer;
}

.calendar-widget-table thead tr th {
    padding: 0px 20px 10px 20px;
}

.calendar-widget-table thead tr th .show-month{
	font-size: 0.5em !important;
}

.calendar-widget-table thead tr th{
	    background-color: #f9af00 !important;
}
#allEvents ul li, #HomepageEvents ul li{
	    background-color: #f9af00 !important;
	    *{
	    color: #fff !important;
	    }
}
#HomepageEvents ul li,
#allEvents ul li{
	position: relative;
}
#allEvents ul li .toggleBtn, #HomepageEvents ul li .toggleBtn {
 	padding: 0px !important;
    float: left;
    width: 100%;
}

.calday{
	position: absolute;
    top: 10px;
    left: 19px;
}
.eventTitle{
	padding-left: 110px;
	padding-right: 35px;
	width: 100% !important;
}
.topLinks{
	position: absolute;
	display: inline-block;
	left: auto;
	top: 20px;
	    z-index: 9; 
	right: 20px;
	span{
	position: relative;
	vertical-align: middle;
		display: inline-block;
		margin: 5px;
		height: 40px;
		width: 40px;
		font-size: 1.4em;
		text-align: center;
		background-color: #626261;
		@include border-radius(50%);
		color:#f9af01;
		img{
		margin-top: 4px;
			width: 71%;
		}
		*{color:#f9af01;}
	}
}

.searchToggle{
	    padding-top: 4px;
}


.SearchContainer{
	display: none;    
	width: 260px;
    right: 10px;
	position: absolute;
    bottom: 0px;
    @include transform(translateY(100%))
    background-color: #626261;
    padding: 7px;
    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }
    *{
    float: left;
    }
    input[type=submit]{
	    background-color: #f9af01;
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;
	    @include border-radius(0px !important);
	    i{
	    	color: #fff !important;
	    }
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px;
	    color: #5f5e6d;
	    @include border-radius(0px !important);
    }
}

#SearchResults{
	h2{
			color: #f9af00 !important;
		a{
			color: #f9af00 !important ;
	    	/*font-family: 'Cambo', serif !important;*/
	    }
	}
}

#__toolbox-opener {
    z-index: 9999 !important;
}

#FooterSliderContainer{
	position: absolute !important;
	top: 0px;
	left: 0px;
	width: 100% !important;
	height: 100% !important;
	*{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	-webkit-background-attachment: fixed;
	}
}
.contentPage {
	table td,
	table th{
	
		padding: 5px;
		border: 1px solid #eee;
	}
	table th{
		background-color: #f9f9f9;
	}
	table { 
		max-width: 100%;
		margin-bottom: 20px;
		border: 1px solid #eee;
	    border-spacing: 0px;
	    border-collapse: separate;
	}
}


.InnerPage{
	.darken{ 
		display: none !important;
	}
}

#LatestNews{
	display: none !important;
}

.ContactFormForm{
	float: left;
	width: 100%;
	max-width: 500px;
}

.pageListLink{
	display: block;
	width: 100%;
	float: left;
	color: #00c0f9;
	margin-bottom: 10px;
	padding: 10px 20px;
	background-color: #efefef;
	@include transition($t1);
	&:hover{
	color: #fff !important;
		background-color: #aaa;
	}
}

table{
	max-width: 100% !important;
}
img{
	height: auto;
}
/* -------------------- Ticker -------------------- **/

.hide.mainContainer{opacity: 0;}


.loadingSection{
	position: fixed;
	top: 0px;
	left: 0px;
	background-color: #fff;
	width: 100%;
	z-index: 9999;
	height: 100%;
	img{
		width: 40px;
		top: 50%;
		left: 50%;
		position: absolute;
		@include transform($center);
	}
}


#FooterSliderContainer{
	width: 100%;
	*{
	width: 100% !important;
	height: 100% !important;
	position: absolute !important;
	max-width: 100% !important;
	max-height: 100% !important;
	}
}

.MainArea{
	display: block;
}