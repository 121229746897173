
/* ------------  General Site Responsive ----------- */
@media all and (min-width: 1371px)  {
	#MobileNavigation{
		display: none !important;
	}
}
@media all and (max-width: 1050px)  {
	#MenuIcon{
		display: block !important;
	}
	#Menu{
		display: none !important;
	}
	.homebtn {
		float: left;
		padding-left: 20px;
		*{
			display: inline-block !important;
			vertical-align: middle;
			font-size: 1.2em !important;
		}
		img{
			width: 70px;
			margin-right: 10px;
		}
	}
	.ScrollNav .homebtn,
	.homebtn{
		margin-bottom: 0px;
		margin-top: 3px;
		img{
			width: 50px;
		}
		span{
			font-size: 1em;
		}
	}
	.topLinks {
	    top: 7px;
	    right: 70px;
	}
}

@media all and (max-width: 700px)  {
	.TwoColumns{
		width: 100% !important;
		clear: both !important;
		padding: 0px !important;
	}
}
@media all and (max-width: 786px)  {
	.tacMid{
		text-align: center !important;
	}
	.clearMid{
		width: 100%;
		padding: 0px !important;
		clear: both;
	}
}
/*** Newsletters Page Responsive ***/
@media all and (max-width: 1150px)  {
	.NewsletterItem{
		width: 33.33% !important;
	}
}
@media all and (max-width: 726px)  {
	.NewsletterItem{
		width: 50% !important;
	}
}
@media all and (max-width: 500px)  {
    .NewsletterItem{
		width: 100% !important;
	}
}
/*** Newsletters Page Responsive ***/

/*** Staff Responsive ***/
@media all and (max-width: 520px)  {
	.StaffPage .staffProfile .profilePic {
	    margin: 0 0px 30px 0 !important;
	    float: left;
	    width: 100%;
	    clear: both;
	    display: block;
	}
}
/*** Staff Responsive ***/
/*** Gallery Responsive ***/

@media all and (max-width: 800px)  {
    .galleryImage{
        width: 50% !important;
        padding-bottom: 50% !important;
    }
}
@media all and (max-width: 768px)  {
	.grid-item, .grid-sizer { 
		width: 50% !important; 
	}
}
@media all and (max-width: 500px)  {
    .galleryImage{
        width: 100% !important;
        padding-bottom: 100% !important;
    }
}

@media all and (max-width: 450px)  {
	.grid-item, .grid-sizer { 
		width: 100% !important; 
	}
}
/*** Gallery Responsive ***/

/* ------------  General Site Responsive ----------- */

/* ------------  Pages ----------- */

@media all and (max-width: 1370px)  {

    #header:before {
    	display: none !important;
	}
	#Menu .MenuContainer .LevelOne a{
		padding-bottom: 24px;
	}
	#header{
		min-height: 65px;
		padding-top: 10px !important;
		padding-bottom: 0px !important;
	}
	.ScrollNav .logo,
	.logo{
		top: 10px;
		img{
			width: 40px;
		}
	}
}


@media all and (max-width: 1060px)  {
	#SliderOneContainer #SliderOne .Slide{
		padding-bottom: 100%;
	}
	#HistoryLeft,
	#HistoryRight,
	#Home3 .col-6{
		width: 100%;
		clear: both !important;
	}

	#Home3 .col-6:last-of-type{
		padding-top: 50px;
	}
	.ContactInfo{
		display: none !important;
	}
	.HomeEventList{
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
}


@media all and (max-width: 1656px)  {
	#Home2 .next {
		top: 91px;
		right: 20px;
	    /*right: auto;
	    left: 50%;
	    margin-left: 10px;
	    @include transform(translateX(0%));*/
	}
	#Home2 .prev {
		top: 91px;
		left: 20px;
	    /*right: auto;
	    left: 50%;
	    margin-left: -10px; 
	    @include transform(translateX(-100%));*/
	}
	#Home2 .pageTitle {
	    color: #f0b130;
	    margin-bottom: 40px;
	}
}
@media all and (max-width: 1030px)  {
	.parallax.historyBg,
	.parallax.bg{
		@include transform(translate(0,0) !important);
		margin-top: 0px !important;
	}
	.bannerText{
		margin-bottom: 0px !important;
		top: auto !important;
		left: 50% !important;
		bottom: 40px !important;
		@include transform(translateX(-50%) translateY(0%) !important);
		opacity: 1 !important;
	}
}
@media all and (max-width: 1370px)  {
	.LinkBox-1,
	.LinkBox-2,
	.LinkBox-3{
		width: 33.33%;
	}
	.LinkBox-4,
	.LinkBox-5{
		width: 50%;
		.innerLinkBox{
			padding-bottom: 50%;
		}
	}
}

@media all and (max-width: 1720px)  {
	#HistoryBtn {
    	top: 120px;
	}
}
@media all and (max-width: 1230px)  {
	.bannerText {
		font-size: 1em !important;
	} 
}
@media all and (max-width: 808px)  {
	.bannerText {
		font-size: 0.6em !important;
	} 
	.ScrollDown{
		display: none; 
	}
}
@media all and (max-width: 980px)  {
	#WelcomeContent{
		width: 100% !important;
	}
}
@media all and (max-width: 880px)  {
	.LinkBox-1,
	.LinkBox-2,
	.LinkBox-4,
	.LinkBox-3{
		width: 50%;
		.innerLinkBox{
			padding-bottom: 100%;
		}
	}
	.LinkBox-5{
		width: 100%;
		.innerLinkBox{
			padding-bottom: 50%;
		}
	}
}

@media all and (max-width: 700px)  {
	.NextHistory,
	.BackToHome{
		display: none !important;
	}
	.bottomLine,
	.rightLine,
	.circle{
		display: none !important;
	}
	.closeHistory{
		right: 20px;
		left: auto !important;
	}

	.InnerBlock,
	.HistorySection,
	.InfoContainer{
		clear: both !important;
		top: auto !important;
		bottom: auto !important;
		border-top: none !important;
		border-bottom: none !important;
		opacity: 1 !important;
		position: relative !important;
		width: 100% !important;
		float: left !important;
		left: auto !important;
		right: auto !important;
		@include transform(translate(0,0) !important);
		&:after,
		&:before{
			display: none !important;
		}
	}
	.InnerBlock{
		height: auto !important;
		padding: 20px 0px;
	}
}
@media all and (max-width: 690px)  {
	.pageTitle{
		font-size: 1.8em;
	}
}
@media all and (max-width: 620px)  {
	#HistoryInner{
		width: 100% !important;
	}
	#HistoryContainer{
		overflow: auto;
    	overflow-x: hidden;
	}
	
	.emailFooter {
		font-size: 0.7em;
	}

}
@media all and (max-width: 1612px)  {
	.HomeEvent{
	font-size: 0.7em;
	}
}
@media all and (max-width: 560px)  {
	.HomeEventTitle,
	.HomeEventDate{
		clear: both;
		width: 100%;
		text-align: left !important;
		padding: 5px;
	}
	.HomeEventTitle{
		padding-left: 5px !important;
	}
}
@media all and (max-width: 620px)  {

	.LinkBox-1,
	.LinkBox-2,
	.LinkBox-4,
	.LinkBox-3,
	.LinkBox-5{
		width: 100%;
		.innerLinkBox{
			padding-bottom: 60%;
		}
	}
	#HistoryLeft,
	#HistoryRight{
		font-size: 0.8em;
	}
	.welcomeTitle {
	    font-size: 1.4em;
	}
}

/* ------------  Pages ----------- */
/* ------------  Inner Pages ----------- */

@media all and (max-width: 1000px)  {
	.InnerPage{
		.flex{
			display: block;
		}
		.innerContent {
		    padding-top: 0px !important;
		    padding-left: 10px !important;
		    padding-right: 10px !important;
		}
	}
	#sidebar{
		border-right: none !important;
		margin-bottom: 0px !important;
		padding-left: 10px;
		padding-right: 10px;
	}
	#sidebar .sidebar-heading{
		padding-bottom: 0px !important;
	}
}



/* ------------  Inner Pages ----------- */
/* ------------ Site Build ----------- */
@media all and (max-width: 1360px)  {
	#Home5 .overlay1,
	#Home5 .overlay2,
	#Home1 .overlay1,
	#Home1 .overlay2{
		display: none !important;
	}
	.col-4.p10.CalEventSlide {
	    float: none;
	    margin: 0 auto;
	    clear: both;
	    width: 100%;
	    max-width: 700px;
	}
}

@media all and (max-width: 1150px)  {
	.LinkBox{
		width: 50%;
	}
}
@media all and (max-width: 1050px)  {
	#footer{
		.col-8,
		.col-4,
		.col-7,
		.col-5{
			width: 100%;
			clear: both;
			text-align: center !important
		}
		.tar{
			text-align: center;
			width: 100%;
			float: left;
			clear: both;
		}
	}
	#up{
		display: none;
	}
	.logos{
		text-align: center !important;
	}
}
@media all and (max-width: 1150px)  {
	#Home4{
		.leftImage{
			position: relative;
			width: 100%;
			clear: both;
			top: auto;
			left: auto;
			top: auto;
			float: left;
			padding-bottom: 30%;
			right: auto;
			@include transform(translate(0px, 0px));
		}
		.rightContent{
			width: 100% !important;
		}
	}

}


@media all and (max-width: 860px)  {
	#Home4{ 
		.leftImage{
			padding-bottom: 50%;
		}
	}

	.slideInner{
		.col-8,
		.col-4{
			width: 100%;
			clear: both;
			text-align: center;
		}
		.col-4{
			max-width: 200px;
			float: none;
			margin: 0 auto;
			clear: both;
		}
	}
	.NewsSlideTitle{
		font-size: 1.3em;
	}
}

@media all and (max-width: 600px)  {
	.LinkBox{
		width: 100%;
	}
	.LinkBox .linkBoxTitle {
	    font-size: 1.5em;
	}
}
@media all and (max-width: 500px)  {
	.EventBottom{
		.col-8,
		.col-4{
			width: 100%;
			clear: both;
			padding: 5px;
		}
	}
}
@media all and (max-width: 600px)  {
	.EventInner{
		display: block;
		text-align: center;
		.col-3,
		.col-9{
			width: 100%;
			clear: both;
			text-align: center;
		}
		.col-3{
			max-width: 100px;
		    float: none;
		    display: inline-block;
		    padding-bottom: 10px !important;
		}
		.EventBottom{
			position: relative;
    		clear: both;
		    width: 100%;
		    left: 0px;
		    margin-top: 10px;
		    bottom: auto;
		    float: left;
		}
	}
	#LatestNews{
		display: none !important;
	}

	.logos{
		display: none !important;
	}
}

@media all and (max-width: 1030px)  {
	*{
		background-attachment: initial !important;
		-webkit-background-attachment: initial !important;
	}
}

@media all and (max-width: 1030px)  {
	.innerPagecontent{
		.MainArea,
		.ImageArea{
			width: 100%;
			clear: both;
		}

		.Rightimg{
			padding-bottom: calc(50% - 10px);
			clear: none;
			min-width: 0px;
			width: calc(50% - 10px) !important;

		}

		.Rightimg:last-of-type{
			float: right;
		}
	}
}

@media all and (max-width: 500px)  {
	.ScrollNav .homebtn, .homebtn {
	    margin-bottom: 0px;
	    margin-top: -1px;
	    font-size: 0.7em;
	}

	.ScrollNav .homebtn, .homebtn {
	    span{ width: 100px;
	    text-align: left;
	    }
	}

	.homebtn img {
	    width: 70px;
	    margin-right: 0px;
	}
	.homebtn {
	    float: left;
	    padding-left: 10px;
	}
	.topLinks {
		right: 60px;
		@include transform(scale(0.7));
		span{
			margin: 5px 1px;
		}
	}
}
@media all and (max-width: 480px)  {
	.innerPagecontent .Rightimg{
		padding-bottom: 100%;
		width: 100% !important;
		clear: both; 
	}
}
/* ------------ Site Build ----------- */



/* iPad */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  {

	.slink.down{
		display: none;
	}
}
